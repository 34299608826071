import http from 'views/utilities/http'

const URL = 'evo/list_member?'
const actionUserApi = {
    getUser(params) {
        return http.get(`${URL}${params}`)
    }
}

export default actionUserApi
