/* eslint-disable no-undef */
export const LocalStorageEventTarget = new EventTarget() // dùng evenTarget của javascript để lắng nghe sự kiện của nó

export const setAccessTokenToLS = (access_token) => {
    localStorage.setItem('access_token', access_token)
}

export const setRefreshTokenToLS = (refresh_token) => {
    localStorage.setItem('refresh_token', refresh_token)
}

export const clearLS = () => {
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('profile')
    // localStorage.clear
    const clearLSEvent = new Event('clearLS') // khi hàm này được chạy thì đồng thời nó cũng thông báo tới 1 sự kiện có tên là 'clearLS' để chạy nó, ta để nó ở file App.tsx
    LocalStorageEventTarget.dispatchEvent(clearLSEvent)
}

export const getRefreshTokenFromLS = () =>
    localStorage.getItem('refreshToken') || ''

export const getAccessTokenFromLS = () =>
    localStorage.getItem('accessToken') || null

export const getProfileFromLS = () => {
    const result = localStorage.getItem('profile')
    return result ? JSON.parse(result) : null
}

export const setProfileToLS = (profile) => {
    localStorage.setItem('profile', JSON.stringify(profile))
}
