/* eslint-disable no-undef */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authApi from 'apis/auth.api'
// import userApi from 'api/user.api'
import LocaStorage from 'constants/localStorage'
import { getAccessTokenFromLS } from 'views/utilities/auth'
import { payloadCreator } from 'views/utilities/helper'

// export const register = createAsyncThunk(
//   'auth/register',
//   payloadCreator(authApi.register)
// )

export const login = createAsyncThunk(
    'auth/login',
    payloadCreator(authApi.login)
)

export const logout = createAsyncThunk(
    'auth/logout',
    payloadCreator(authApi.logout)
)

// export const updateMe = createAsyncThunk(
//   'auth/updateMe',
//   payloadCreator(userApi.updateMe)
// )

const handleAuthFulfilled = (state, action) => {
    const { access_token, token_type } = action.payload
    if (action.payload.result) {
        localStorage.setItem(LocaStorage.accessToken, `Bearer ${access_token}`)
    } else {
        localStorage.removeItem(LocaStorage.accessToken)
    }
    state.isAuthenticated = Boolean(getAccessTokenFromLS())
}

const handleUnauth = (state) => {
    state.profile = {}
    localStorage.removeItem(LocaStorage.accessToken)
    state.isAuthenticated = Boolean(getAccessTokenFromLS())
}

const initialState = {
    isAuthenticated: Boolean(getAccessTokenFromLS()),
    profile: JSON.parse(localStorage.getItem(LocaStorage.user)) || {}
}
const auth = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        unauthorize: handleUnauth
    },
    extraReducers: {
        // [register.fulfilled]: handleAuthFulfilled,
        [login.fulfilled]: handleAuthFulfilled,
        [logout.fulfilled]: handleUnauth
        // [updateMe.fulfilled]: (state, action) => {
        //   state.profile = action.payload.data
        //   localStorage.setItem(LocaStorage.user, JSON.stringify(state.profile))
        // }
    }
})

const authReducer = auth.reducer
export const unauthorize = auth.actions.unauthorize
export default authReducer
