import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import historyApi from 'apis/history.api';

import { handleMiddlewareLoadingAction } from 'store/helper'

const getSlotApi = createAsyncThunk(
    'slot/get',
    async (query) => handleMiddlewareLoadingAction(() => historyApi.getSlotHistory(query), 'slot/get')
)

const initialState = {
    slot: undefined
}

const slotSlice = createSlice({
    name: 'slot',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSlotApi.fulfilled , (state, {payload}) =>{
            state.slot = payload
        })
    }
})

export const thunkAction = {
    getSlotApi
}

export default slotSlice.reducer
