import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const ButtonCustomized = styled(Button)`
    color: #444;
    font-size: 13px;
    background-color: #fff;
    border-color: #ccc;
`;

function ButtonSearch({ type = 'button', children, onClick, disabled }) {
    return (
        <ButtonCustomized type={type} disabled={disabled} onClick={onClick} variant="outlined" size="small">
            {children}
        </ButtonCustomized>
    );
}

export default ButtonSearch;
