/* eslint-disable no-undef */
import PropTypes from 'prop-types'
import { forwardRef, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Avatar,
    Chip,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery
} from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions'

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { logout } from 'views/pages/authentication/authentication.slice'
import { path } from 'constants/path'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const { isAuthenticated } = useSelector((state) => state.authReducer)
    const token = localStorage.getItem('accessToken')
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    const customization = useSelector((state) => state.customization)
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))
    const { pathname } = useLocation()
    const handleLogout = async () => {
        await dispatch(logout())
            .then(unwrapResult)
            .then((res) => {
                console.log('res', res)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }
    const Icon = item.icon
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size='1.3rem' />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width:
                    customization.isOpen.findIndex((id) => id === item?.id) > -1
                        ? 8
                        : 6,
                height:
                    customization.isOpen.findIndex((id) => id === item?.id) > -1
                        ? 8
                        : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    )

    let itemTarget = '_self'
    if (item.target) {
        itemTarget = '_blank'
    }

    let listItemProps = {
        component: forwardRef((props, ref) =>
            item.id === 'logout' ? (
                <button
                    ref={ref}
                    {...props}
                    target={itemTarget}
                    onClick={handleLogout}
                />
            ) : (
                <Link ref={ref} {...props} to={item.url} target={itemTarget} />
            )
        )
    }
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget }
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id })
        if (matchesSM) dispatch({ type: SET_MENU, opened: false })
    }

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id)
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!token) {
            navigate(path.login)
        }
    }, [token])

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor:
                    level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={pathname === item.url}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={
                            customization.isOpen.findIndex(
                                (id) => id === item.id
                            ) > -1
                                ? 'h5'
                                : 'body1'
                        }
                        color='inherit'
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography
                            variant='caption'
                            sx={{ ...theme.typography.subMenuCaption }}
                            display='block'
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={
                        item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>
                    }
                />
            )}
        </ListItemButton>
    )
}

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
}

export default NavItem
