import dashboard from './dashboard'
import pages from './pages'
import utilities from './utilities'
import other from './other'
import MainMenu from './MainMenu'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [MainMenu]//, dashboard, pages, utilities, other]
}

export default menuItems
