import { useDispatch } from "react-redux";
import { setOpenToast, setSeverityAndTitle } from "ui-component/Toast/toast.slice";

export const useToastControl = () => {
    const dispatch = useDispatch();

    const toastControl = (open, severity, title) => {
        dispatch(setSeverityAndTitle({ severity, title }));
        dispatch(setOpenToast(open));
    };

    return { toastControl };
};