// Đoạn này để kiểm tra xem email có hợp lệ hay không
export const isEmail = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )

export const payloadCreator = (asyncFunc) => async (arg, thunkAPI) => {
  try {
    const res = await asyncFunc(arg)
    return res
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
}

//Thằng này là để lấy 1 đoạn title hay 1 cái tên gì đó để đẩy lên thanh url của trình duyệt, nhưng mà thường thì cái title của nó sẽ có dấu cách, mà trên url thì đâu thể để dấu cách được, nên ta sẽ đổi dấu cách đó thành dấu "-" gạch ngang như vậy,

// Trong đoạn code dưới /s/ có nghĩa là space(khoảng cách), g nghĩa là global(toàn cầu) => nghĩa là lấy hết toàn bộ dấu cách của cái đoạn chuỗi nào đó ,trong đây thì lấy hết đoạn chuỗi là giá trị của name
// Cũng trong đoạn code dưới /%/ nghĩa % thôi :)), nhưng tại sao lại để nó vô, là vì khi ta dùng encodeURIComponent(phan thế cường)  => thì nó sẽ ra như vậy phan%the%cuong => nó sẽ ra như vậy
// Nếu như ta có đoạn như sau encodeURIComponent(sản phẩm này giảm giá 30%) => san%pham%nay%giam%gia%30%%
// Vì có thể sẽ có lỗi như vậy nên ta tạm thời cần loại % ra luôn
// _id chỉ là id thôi, để ta dễ xác định sản phẩm có id như nào

export const generateNameId = ({ name, _id }) =>
  encodeURIComponent(`${name.replace(/\s/g, '-').replace(/%/g, '')}-i.${_id}`)

// Thằng dưới này dùng để chuyển đổi số, ví dụ: 1000000 => 1.000.000
export const formatMoney = (value, character = '.') =>
  String(value).replace(/\B(?=(\d{3})+(?!\d))/g, character)

// Thằng này cũng để chuyển đối số, vi dụ: 1400 => 1.4k
export const formatK = (value) => {
  const price = Number((Number(value) / 1000).toFixed(2))
  if (price > 1) {
    return price + 'k'
  }
  return value
}

// getIdFromNameId này sẽ cắt 1 chuỗi nào đó đến vị trí đầu tiên tìm thấy hợp lệ là đoạn chuỗi '-i.', và hàm split sẽ lấy phần còn lại

//Đây là 1 ví dụ ta lấy được trên thanh url: idproduct Đồng-Hồ-Nam-WWOOR-8018-Dây-Thép-Nhật-Cao-Cấp-Nhiều-Màu-i.60afad846ef5b902180aacb0
export const getIdFromNameId = (url) => {
  const arr = url.split('-i.') // bỏ hết từ đầu đến đoạn -i.' và lấy hết phần còn lại gán cho arr
  return arr[arr.length - 1]
}

// Tính phần trăm giá sale
export const rateSale = (original, sale) =>
  Math.round(((original - sale) / original) * 100) + '%'

export const filterData = (data) => {
  if (!data) return
  const formattedData = []
  for (let item of data) {
    if (item.mb_parent_id === '') {
      formattedData[item.mb_id] = { ...item, childrens: [] }
    } else {
      let tmp = formattedData
      for (let index = 1; index < item.mb_step; index++) {
        if (tmp[item.parents['step_' + index]] !== undefined) {
          tmp = tmp[item.parents['step_' + index]]['childrens']
        }
      }
      tmp[item.mb_id] = { ...item, childrens: [] }
    }
  }

  return formattedData
}

export const filterAndExtractChildrens = (data) => {
  const convertArray = Object.values(data)
  return convertArray.map((item) => ({
    ...item,
    childrens: filterAndExtractChildrens(item.childrens)
  }))
}
