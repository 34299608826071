import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import actionUserApi from 'apis/actionUserApi.api'
import { payloadCreatorAndLoading } from 'store/helper'

import { payloadCreator } from 'views/utilities/helper'

export const getUserList = createAsyncThunk(
    'user/list',
    payloadCreatorAndLoading(actionUserApi.getUser, 'user/list')
)

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {
            active_sort: '',
            filter: [],
            header: {},
            data: []
        }
    },
    extraReducers: {
        [getUserList.fulfilled]: (state, action) => {
            if (action.payload.code === 200) {
                state.data = {
                    active_sort: action.payload.active_sort,
                    filter: action.payload.head.order_by,
                    header: action.payload.header,
                    data: action.payload.list.data
                }
            }
        }
    }
})

const userReducer = userSlice.reducer
export default userReducer
