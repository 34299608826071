import http from 'views/utilities/http'

const URL_SLOT_BETTING = '/evo/betting/betting_rolling/slot'
const URL_CASINO_BETTING = '/evo/betting/betting_rolling/casino'
const URL_BETTING = '/evo/betting/betting_rolling'

const bettingApi = {
    getBettingSlot: (query) => http.get(`${URL_SLOT_BETTING}`, {params: query}),
    getBettingCasino: (query) => http.get(`${URL_CASINO_BETTING}`, {params: query}),
    getBettingTotal: (query) => http.get(`${URL_BETTING}`, {params: query}),
}


export default bettingApi
