import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import historyApi from 'apis/history.api';

import { handleMiddlewareLoadingAction } from 'store/helper'

const getUserApi = createAsyncThunk(
    'user/get',
    async (query) => handleMiddlewareLoadingAction(() => historyApi.getUserHistory(query), 'user/get')
)

const initialState = {
    user: undefined
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUserApi.fulfilled , (state, {payload}) =>{
            state.user = payload
        })
    }
})

export const thunkAction = {
    getUserApi
}

export default userSlice.reducer
